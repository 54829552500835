<!--管理员管理-->
<template>
  <a-layout-content>
    <div class="nav">接口安全控制</div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="">
              <a-input
                v-decorator="['keyword']"
                allow-clear
                placeholder="请输入关键字"
                @change="onChangeValue"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-row class="add">
        <a-button type="primary" @click="onAdd">
          新建应用系统接口信息
        </a-button>
      </a-row>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="appId" slot-scope="text, record">
            <div class="flexStyle">
              <div>{{ record.appId }}</div>
              <a
                @click="copyAppId()"
                :data-clipboard-text="record.appId"
                class="copy"
              >
                复制
              </a>
            </div>
          </template>
          <template slot="appKey" slot-scope="text, record">
            <div class="flexStyle">
              <div>{{ record.appKey }}</div>
              <a
                @click="copyAppId()"
                :data-clipboard-text="record.appKey"
                class="copy"
              >
                复制
              </a>
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="resetKey(record)" style="min-width: 100px">重置APPKEY</a>
            <a @click="openDelete(record)">删除</a>
          </a-space>
        </a-table>
      </div>
    </div>

    <!--新增弹窗-->
    <a-modal
      title="新建"
      :visible="visible"
      okText="保存"
      cancelText="取消"
      @ok="addAdmin"
      @cancel="handleCancel"
    >
      <a-form :form="addForm" @submit="addAdmin">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="应用系统名称"
        >
          <a-input
            v-decorator="[
              'appName',
              {
                rules: [{ required: true, message: '请输入应用系统名称' }]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!--删除弹窗-->
    <a-modal
      title="删除"
      :visible="delVisible"
      okText="删除"
      cancelText="取消"
      @ok="deleteTag"
      @cancel="delVisible = false"
    >
      <div class="tips">
        确定删除名为【{{ appInfo.appName }}】的系统接口信息吗?
      </div>
    </a-modal>
  </a-layout-content>
</template>
<script>
import Clipboard from "clipboard";
import {
  addApp,
  deleteApp,
  getAppList,
  resetAppKey
} from "@/services/apiSecuraty";
const columns = [
  {
    title: "应用系统名称",
    dataIndex: "appName"
  },
  {
    title: "APPID",
    dataIndex: "appId",
    scopedSlots: { customRender: "appId" }
  },
  {
    title: "APPKEY",
    dataIndex: "appKey",
    scopedSlots: { customRender: "appKey" }
  },
  {
    title: "创建人",
    dataIndex: "createdBy",
    width: "10%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};
export default {
  name: "ApiSecuraty",
  data() {
    return {
      columns,
      formItemLayout,
      form: this.$form.createForm(this),
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: [],
      addForm: this.$form.createForm(this),
      visible: false,
      appInfo: {},
      delVisible: false // 删除弹窗
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    onChangeValue(row) {
      if (row.type === "click") {
        this.onReset();
      }
    },
    // 获取表格数据
    async getTableList() {
      const params = this.form.getFieldsValue();
      const obj = {
        keyword: params.keyword ? params.keyword : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getAppList(obj);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.pagination.current = 1;
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    // 打开删除弹窗
    openDelete(record) {
      this.appInfo = record;
      this.delVisible = true;
    },
    // 删除
    async deleteTag() {
      const { data } = await deleteApp(this.appInfo.appId);
      if (data.code === "SUCCESS") {
        this.$message.success("删除成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
      this.delVisible = false;
      this.appInfo = {};
    },
    // 点击 新建应用系统接口信息
    onAdd() {
      this.addForm.resetFields();
      this.visible = true;
    },
    // 重置appKey
    async resetKey(record) {
      const { data } = await resetAppKey(record.appId);
      if (data.code === "SUCCESS") {
        this.$message.success("重置成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 提交
    async addAdmin() {
      const params = this.addForm.getFieldsValue();
      const { data } = await addApp(params);
      if (data.code === "SUCCESS") {
        this.$message.success("添加成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
      this.visible = false;
    },
    handleCancel() {
      this.addForm.resetFields();
      this.visible = false;
    },
    // 复制 appId appKey
    copyAppId() {
      const clipboard = new Clipboard(".copy");
      clipboard.on("success", () => {
        this.$message.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.error("复制失败");
        clipboard.destroy();
      });
    }
  }
};
</script>
<style scoped>
.nav {
  background-color: white;
  font-size: 30px;
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #cccccc;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  padding: 30px;
}
.add {
  margin-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  margin-top: 4px;
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
.tips {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}
.flexStyle {
  display: flex;
  justify-content: space-between;
}
a {
  display: inline-block;
  min-width: 30px;
}
</style>
