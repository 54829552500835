<!--自定义标签的   二级标签-->
<template>
  <a-layout-content>
    <div class="nav">
      <a-icon type="left" @click="onBack" />
      {{ tagName }}的自定义二级标签
    </div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="">
              <a-input
                v-decorator="['keyword']"
                allow-clear
                placeholder="请输入关键字"
                @change="onChangeValue"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="openDelete(record)">删除</a>
          </a-space>
        </a-table>
      </div>
    </div>

    <!--删除弹窗-->
    <a-modal
      title="删除自定义二级标签"
      :visible="delVisible"
      okText="删除"
      cancelText="取消"
      @ok="deleteTag"
      @cancel="delVisible = false"
    >
      <div class="tips">
        确定删除名为【{{ tagInfo.tagName }}】的自定义二级标签吗?
      </div>
    </a-modal>
  </a-layout-content>
</template>
<script>
import { getCustomTagList, deleteCustomTag } from "@/services/customTag";
const columns = [
  {
    title: "标签名称",
    dataIndex: "tagName"
  },
  {
    title: "创建人",
    dataIndex: "createdBy"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};
export default {
  name: "SelfSecondTag",
  data() {
    return {
      parentTagId: this.$route.query.id, // 上级标签id
      tagName: this.$route.query.tagName, // 上级标签name
      columns,
      formItemLayout,
      form: this.$form.createForm(this),
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: [],
      tagInfo: {},
      delVisible: false // 删除弹窗
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    onChangeValue(row) {
      if (row.type === "click") {
        this.onReset();
      }
    },
    // 返回上一级
    onBack() {
      this.$router.back();
    },
    // 获取表格数据
    async getTableList() {
      const params = this.form.getFieldsValue();
      const obj = {
        keyword: params.keyword ? params.keyword : "",
        parentTagId: this.parentTagId,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getCustomTagList(obj);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.pagination.current = 1;
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    // 打开删除弹窗
    openDelete(record) {
      this.tagInfo = record;
      this.delVisible = true;
    },
    // 删除标签
    async deleteTag() {
      const { data } = await deleteCustomTag(this.tagInfo.tagId);
      if (data.code === "SUCCESS") {
        this.$message.success("删除成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
      this.delVisible = false;
      this.tagInfo = {};
    }
  }
};
</script>
<style scoped>
.nav {
  background-color: white;
  font-size: 30px;
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #cccccc;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  padding: 30px;
}
.add {
  margin-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  margin-top: 4px;
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
.tips {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}
</style>
