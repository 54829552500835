var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_vm._v("标签组管理")]),_c('div',{staticClass:"content-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.onSearch}},[_c('a-row',[_c('a-col',{attrs:{"span":7}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keyword']),expression:"['keyword']"}],attrs:{"allow-clear":"","placeholder":"请输入关键字"},on:{"change":_vm.onChangeValue}})],1)],1),_c('a-col',{staticClass:"search__btn",attrs:{"span":7}},[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btn-reset",attrs:{"type":"white","icon":"undo"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('a-row',{staticClass:"add"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onAdd}},[_vm._v(" 新建标签组 ")])],1),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tableData,"columns":_vm.columns,"pagination":_vm.pagination,"row-key":(record, index) => {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"tagList",fn:function(text, record){return [_c('div',[_c('draggable',{attrs:{"list":record.tagList,"animation":200,"filter":".unmover"},on:{"end":function($event){return _vm.draggerEnd(record)}}},[_vm._l((record.tagList),function(item,index){return _c('span',{key:index,staticClass:"tag"},[_vm._v(" "+_vm._s(item.tagName)+" "),_c('a-icon',{staticClass:"del-icon",attrs:{"type":"close"},on:{"click":function($event){return _vm.openDelTagModal(item, record)}}})],1)}),_c('a-tag',{staticClass:"unmover",staticStyle:{"background":"#fff","borderStyle":"dashed"},on:{"click":function($event){return _vm.openAddTagModal(record)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加标签 ")],1)],2)],1)]}},{key:"action",fn:function(text, record){return _c('a-space',{},[_c('a',{staticStyle:{"min-width":"30px"},on:{"click":function($event){return _vm.openEdit(record)}}},[_vm._v("编辑")]),_c('a',{staticStyle:{"min-width":"30px"},on:{"click":function($event){return _vm.openDelete(record)}}},[_vm._v("移除")]),_c('a',{staticStyle:{"min-width":"100px"},on:{"click":function($event){return _vm.openDetailModal(record)}}},[_vm._v(" 查看标签组详情 ")])])}}])})],1)],1),_c('a-modal',{attrs:{"title":_vm.modalTitle,"visible":_vm.visible,"okText":"保存","cancelText":"取消"},on:{"ok":_vm.addTagGroup,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.addForm},on:{"submit":_vm.addTagGroup}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"标签组名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tagGroupName',
            {
              rules: [{ required: true, message: '请输入标签组名称' }]
            }
          ]),expression:"[\n            'tagGroupName',\n            {\n              rules: [{ required: true, message: '请输入标签组名称' }]\n            }\n          ]"}]})],1)],1)],1),_c('a-modal',{attrs:{"title":"删除标签组","visible":_vm.delVisible,"okText":"删除","cancelText":"取消"},on:{"ok":_vm.deleteTagGroup,"cancel":function($event){_vm.delVisible = false}}},[_c('div',{staticClass:"tips"},[_vm._v(" 确定删除名为【"+_vm._s(_vm.tagGroupInfo.tagGroupName)+"】的标签组吗? ")]),_c('div',{staticClass:"tips"},[_vm._v("该分组下的标签也会一并删除")])]),_c('a-modal',{attrs:{"width":"800px","title":"查看标签组详情","visible":_vm.detailVisible,"footer":false},on:{"cancel":function($event){_vm.detailVisible = false}}},[_c('a-table',{attrs:{"data-source":[_vm.tagGroupInfo],"columns":_vm.detailColumns,"pagination":false,"row-key":(record, index) => {
          return index;
        }},scopedSlots:_vm._u([{key:"createTime",fn:function(text, record){return [_c('div',[_vm._v(_vm._s(_vm.tsFormat(record.createTime)))])]}}])})],1),_c('a-modal',{attrs:{"title":"添加标签","visible":_vm.addTagVisible,"okText":"保存","cancelText":"取消"},on:{"ok":_vm.addTag,"cancel":function($event){_vm.addTagVisible = false}}},[_c('a-form',{attrs:{"form":_vm.addTagForm},on:{"submit":_vm.addTag}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"添加的标签名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tagName',
            {
              rules: [{ required: true, message: '请输入添加的标签名称' }]
            }
          ]),expression:"[\n            'tagName',\n            {\n              rules: [{ required: true, message: '请输入添加的标签名称' }]\n            }\n          ]"}]})],1)],1)],1),_c('a-modal',{attrs:{"title":"移除标签","visible":_vm.delTagVisible,"okText":"删除","cancelText":"取消"},on:{"ok":_vm.deleteTag,"cancel":function($event){_vm.delTagVisible = false}}},[_c('div',{staticClass:"tips"},[_vm._v("确定删除名为【"+_vm._s(_vm.tagInfo.tagName)+"】的标签吗?")]),_c('div',{staticClass:"tips"},[_vm._v("该分组的下级标签也会一并删除")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }