var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_vm._v(" 管理员管理 ")]),_c('div',{staticClass:"content-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.onSearch}},[_c('a-row',[_c('a-col',{attrs:{"span":7}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keyword']),expression:"['keyword']"}],attrs:{"allow-clear":"","placeholder":"请输入姓名/账号"},on:{"change":_vm.onChangeValue}})],1)],1),_c('a-col',{staticClass:"search__btn",attrs:{"span":7}},[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btn-reset",attrs:{"type":"white","icon":"undo"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('a-row',{staticClass:"add"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onAdd}},[_vm._v(" 添加新的管理员 ")])],1),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tableData,"columns":_vm.columns,"pagination":_vm.pagination,"row-key":(record, index) => {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"roleIdList",fn:function(text, record){return [_c('div',[_vm._v(_vm._s(_vm.transValue(record.roleIdList)))])]}},{key:"createTime",fn:function(text, record){return [_c('div',[_vm._v(_vm._s(_vm.tsFormat(record.createTime)))])]}},{key:"action",fn:function(text, record){return _c('a-space',{},[_c('a-popconfirm',{attrs:{"title":`您确定要移除该管理员吗?`,"ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.deleteRole(record)}}},[_c('a',[_vm._v("删除")])]),_c('a',{on:{"click":function($event){return _vm.editRole(record)}}},[_vm._v("管理角色")])],1)}}])})],1)],1),_c('a-modal',{attrs:{"title":"添加新的管理员","visible":_vm.visible,"okText":"保存","cancelText":"取消"},on:{"ok":_vm.addAdmin,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.addForm},on:{"submit":_vm.addAdmin}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"管理员手机号/邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userName',
            {
              rules: [
                { required: true, message: '请输入管理员手机号/邮箱' },
                {
                  validator: _vm.validateEmail,
                  message: '请输入正确格式的手机号/邮箱'
                }
              ]
            }
          ]),expression:"[\n            'userName',\n            {\n              rules: [\n                { required: true, message: '请输入管理员手机号/邮箱' },\n                {\n                  validator: validateEmail,\n                  message: '请输入正确格式的手机号/邮箱'\n                }\n              ]\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"管理员角色"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'roleIds',
            {
              rules: [{ required: true, message: '请选择管理角色' }]
            }
          ]),expression:"[\n            'roleIds',\n            {\n              rules: [{ required: true, message: '请选择管理角色' }]\n            }\n          ]"}]},[_c('a-checkbox',{attrs:{"value":"SYS_ADMIN"}},[_vm._v("标签系统管理员")]),_c('a-checkbox',{attrs:{"value":"APP_ADMIN"}},[_vm._v("应用系统管理员")])],1)],1)],1)],1),_c('a-modal',{attrs:{"title":"管理该管理员角色","visible":_vm.editVisible,"okText":"保存","cancelText":"取消"},on:{"ok":_vm.editAdmin,"cancel":_vm.editAdminCancel}},[_c('a-form',{attrs:{"form":_vm.editForm},on:{"submit":_vm.addAdmin}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"管理员手机号"}},[_c('div',[_vm._v(_vm._s(_vm.adminInfo.mobile || "--"))])]),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"管理员邮箱"}},[_c('div',[_vm._v(_vm._s(_vm.adminInfo.email || "--"))])]),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"管理员姓名"}},[_c('div',[_vm._v(_vm._s(_vm.adminInfo.nickName || "--"))])]),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"管理员角色"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'roleIds',
            {
              rules: [{ required: true, message: '请选择管理角色' }]
            }
          ]),expression:"[\n            'roleIds',\n            {\n              rules: [{ required: true, message: '请选择管理角色' }]\n            }\n          ]"}]},[_c('a-checkbox',{attrs:{"value":"SYS_ADMIN"}},[_vm._v("标签系统管理员")]),_c('a-checkbox',{attrs:{"value":"APP_ADMIN"}},[_vm._v("应用系统管理员")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }