import { apiClient, apiClients } from "@/services/HttpService";
// apiClient 我们的接口;; apiClients 外部接口
// 设置密码-发送短信验证码（采用外部接口）
export function sendMessageCodeInPwd(params) {
  return apiClients.post("/User/FindPwd", params);
}
// 设置密码-发送邮箱验证码（采用外部接口）
export function sendEmailCodeInPwd(params) {
  return apiClients.post("User/findMailPwd", params);
}

// 验证码登录-发送短信验证码（采用外部接口）
export function sendMessageCodeInLogin(params) {
  return apiClients.post("/User/Captcha2Login", params);
}
// 验证码登录-发送邮箱验证码（采用外部接口）
export function sendEmailCodeInLogin(params) {
  return apiClients.post("User/MailCaptcha2Login", params);
}

// 短信验证码 设置密码（采用外部接口）
export function setPassword(params) {
  return apiClients.post("/User/ReRegister", params);
}
// 邮箱验证码 设置密码（采用外部接口）
export function setPasswordByEmail(params) {
  return apiClients.post("/User/mailReRegister", params);
}

// 账号密码登录
export function loginByPwd(params) {
  return apiClients.post(`/User/Login`, params);
}

// 短信验证码登录（采用外部接口）
export function loginByVerifyCode(params) {
  return apiClients.post(`/User/CaptchaLogin`, params);
}
// 邮箱验证码登录（采用外部接口）
export function loginByEmailVerifyCode(params) {
  return apiClients.post(`User/MailCaptchaLogin`, params);
}

// Web端登录统一用户中心成功后，调用此接口同步用户信息，并换取标签管理系统token
export function loginByTrail(params) {
  return apiClient.post(`/login/userInfo`, params);
}
