<!--标签组管理-->
<template>
  <a-layout-content>
    <div class="nav">标签组管理</div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="">
              <a-input
                v-decorator="['keyword']"
                allow-clear
                placeholder="请输入关键字"
                @change="onChangeValue"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-row class="add">
        <a-button type="primary" @click="onAdd">
          新建标签组
        </a-button>
      </a-row>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="tagList" slot-scope="text, record">
            <div>
              <draggable
                :list="record.tagList"
                :animation="200"
                filter=".unmover"
                @end="draggerEnd(record)"
              >
                <span
                  class="tag"
                  v-for="(item, index) in record.tagList"
                  :key="index"
                >
                  {{ item.tagName }}
                  <a-icon
                    type="close"
                    @click="openDelTagModal(item, record)"
                    class="del-icon"
                  />
                </span>
                <a-tag
                  class="unmover"
                  style="background: #fff; borderStyle: dashed;"
                  @click="openAddTagModal(record)"
                >
                  <a-icon type="plus" />
                  添加标签
                </a-tag>
              </draggable>
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="openEdit(record)" style="min-width: 30px">编辑</a>
            <a @click="openDelete(record)" style="min-width: 30px">移除</a>
            <a @click="openDetailModal(record)" style="min-width: 100px">
              查看标签组详情
            </a>
          </a-space>
        </a-table>
      </div>
    </div>

    <!--新增/编辑弹窗-->
    <a-modal
      :title="modalTitle"
      :visible="visible"
      okText="保存"
      cancelText="取消"
      @ok="addTagGroup"
      @cancel="handleCancel"
    >
      <a-form :form="addForm" @submit="addTagGroup">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="标签组名称"
        >
          <a-input
            v-decorator="[
              'tagGroupName',
              {
                rules: [{ required: true, message: '请输入标签组名称' }]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!--删除弹窗-->
    <a-modal
      title="删除标签组"
      :visible="delVisible"
      okText="删除"
      cancelText="取消"
      @ok="deleteTagGroup"
      @cancel="delVisible = false"
    >
      <div class="tips">
        确定删除名为【{{ tagGroupInfo.tagGroupName }}】的标签组吗?
      </div>
      <div class="tips">该分组下的标签也会一并删除</div>
    </a-modal>

    <!--详情弹窗-->
    <a-modal
      width="800px"
      title="查看标签组详情"
      :visible="detailVisible"
      :footer="false"
      @cancel="detailVisible = false"
    >
      <a-table
        :data-source="[tagGroupInfo]"
        :columns="detailColumns"
        :pagination="false"
        :row-key="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="createTime" slot-scope="text, record">
          <div>{{ tsFormat(record.createTime) }}</div>
        </template>
      </a-table>
    </a-modal>

    <!--添加标签弹窗-->
    <a-modal
      title="添加标签"
      :visible="addTagVisible"
      okText="保存"
      cancelText="取消"
      @ok="addTag"
      @cancel="addTagVisible = false"
    >
      <a-form :form="addTagForm" @submit="addTag">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="添加的标签名称"
        >
          <a-input
            v-decorator="[
              'tagName',
              {
                rules: [{ required: true, message: '请输入添加的标签名称' }]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!--移除弹窗-->
    <a-modal
      title="移除标签"
      :visible="delTagVisible"
      okText="删除"
      cancelText="取消"
      @ok="deleteTag"
      @cancel="delTagVisible = false"
    >
      <div class="tips">确定删除名为【{{ tagInfo.tagName }}】的标签吗?</div>
      <div class="tips">该分组的下级标签也会一并删除</div>
    </a-modal>
  </a-layout-content>
</template>
<script>
import draggable from "vuedraggable";
import {
  getTagGroupList,
  deleteTagGroup,
  addTagGroup,
  editTagGroup,
  delTagFromGroup,
  addTagToGroup,
  editTagGroupOrder
} from "@/services/tagGroup";
import { tsFormat } from "@/components/DateUtils";
const columns = [
  {
    title: "标签组ID",
    dataIndex: "tagGroupId"
  },
  {
    title: "标签组名称",
    dataIndex: "tagGroupName",
    width: "10%"
  },
  {
    title: "标签",
    dataIndex: "tagList",
    scopedSlots: { customRender: "tagList" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const detailColumns = [
  {
    title: "标签组ID",
    dataIndex: "tagGroupId"
  },
  {
    title: "标签组名称",
    dataIndex: "tagGroupName"
  },
  {
    title: "标签组创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" }
  },
  {
    title: "标签组创建人",
    dataIndex: "createdBy"
  }
];
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};
export default {
  name: "TagGroup",
  components: { draggable },
  data() {
    return {
      columns,
      detailColumns,
      formItemLayout,
      modalTitle: "", // 弹窗标题
      form: this.$form.createForm(this),
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: [],
      addForm: this.$form.createForm(this),
      visible: false,
      tagGroupInfo: {},
      delVisible: false, // 删除弹窗
      detailVisible: false, // 详情弹窗
      tagInfo: {}, // 标签数据
      delTagVisible: false, // 删除标签弹窗
      tagGroupId: "",
      addTagForm: this.$form.createForm(this),
      addTagVisible: false // 增加标签弹窗
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    tsFormat,
    onChangeValue(row) {
      if (row.type === "click") {
        this.onReset();
      }
    },
    // 获取表格数据
    async getTableList() {
      const params = this.form.getFieldsValue();
      const obj = {
        keyword: params.keyword ? params.keyword : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getTagGroupList(obj);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.pagination.current = 1;
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    // 打开删除弹窗
    openDelete(record) {
      this.tagGroupInfo = record;
      this.delVisible = true;
    },
    // 删除标签组
    async deleteTagGroup() {
      const { data } = await deleteTagGroup(this.tagGroupInfo.tagGroupId);
      if (data.code === "SUCCESS") {
        this.$message.success("删除成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
      this.delVisible = false;
      this.tagGroupInfo = {};
    },
    // 操作-点击编辑按钮
    openEdit(record) {
      this.modalTitle = "编辑标签组";
      this.tagGroupInfo = record;
      this.visible = true;
      this.$nextTick(() => {
        this.addForm.setFieldsValue({
          tagGroupName: record.tagGroupName
        });
      });
    },
    // 点击添加按钮
    onAdd() {
      this.modalTitle = "新建标签组";
      this.addForm.resetFields();
      this.visible = true;
    },
    // 提交 - 添加/编辑
    async addTagGroup() {
      const params = this.addForm.getFieldsValue();
      if (this.tagGroupInfo.tagGroupId) {
        //   编辑
        const { data } = await editTagGroup(
          this.tagGroupInfo.tagGroupId,
          params
        );
        if (data.code === "SUCCESS") {
          this.$message.success("编辑成功");
          this.getTableList();
        } else {
          this.$message.error(data.errorMsg);
        }
      } else {
        // 新增
        const { data } = await addTagGroup(params);
        if (data.code === "SUCCESS") {
          this.$message.success("添加成功");
          this.getTableList();
        } else {
          this.$message.error(data.errorMsg);
        }
      }
      this.visible = false;
      this.tagGroupInfo = {};
    },
    handleCancel() {
      this.addForm.resetFields();
      this.visible = false;
    },
    // 查看详情
    openDetailModal(record) {
      this.tagGroupInfo = record;
      this.detailVisible = true;
    },
    // 删除标签弹窗
    openDelTagModal(item, record) {
      this.tagGroupId = record.tagGroupId;
      this.tagInfo = item;
      this.delTagVisible = true;
    },
    // 移除标签
    async deleteTag() {
      const { data } = await delTagFromGroup(
        this.tagGroupId,
        this.tagInfo.tagId
      );
      if (data.code === "SUCCESS") {
        this.$message.success("移除成功");
        this.getTableList();
        this.delTagVisible = false;
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 打开标签组内添加标签弹窗
    openAddTagModal(record) {
      this.tagGroupId = record.tagGroupId;
      this.addTagForm.resetFields();
      this.addTagVisible = true;
    },
    // 向标签组内添加标签
    async addTag() {
      const params = this.addTagForm.getFieldsValue();
      const { data } = await addTagToGroup(this.tagGroupId, params);
      if (data.code === "SUCCESS") {
        this.$message.success("添加成功");
        this.getTableList();
        this.addTagVisible = false;
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 拖拽事件结束后触发
    async draggerEnd(record) {
      const tagIdList = record.tagList.map(item => item.tagId);
      const { data } = await editTagGroupOrder(record.tagGroupId, tagIdList);
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
        this.getTableList();
      }
    }
  }
};
</script>
<style scoped>
.nav {
  background-color: white;
  font-size: 30px;
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #cccccc;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  padding: 30px;
}
.add {
  margin-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  margin-top: 4px;
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
.tips {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}
.tag {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  margin-bottom: 0.5rem;
}
.del-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.65);
}
a {
  display: inline-block;
}
</style>
