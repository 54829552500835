import { apiClient } from "@/services/HttpService";

// 接口安全控制 相关接口

//获取应用列表
export function getAppList(params) {
  return apiClient.get(
    `/app?pageNum=${params.pageNum}&pageSize=${params.pageSize}&keyword=${params.keyword}`
  );
}

//创建应用
export function addApp(params) {
  return apiClient.post("/app", params);
}

// 删除应用
export function deleteApp(appId) {
  return apiClient.delete(`/app/${appId}`);
}

//重置应用key
export function resetAppKey(appId) {
  return apiClient.put(`/app/resetAppKey/${appId}`);
}
