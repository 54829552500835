<template>
  <a-layout class="main__container">
    <img src="@/assets/image/login-bg.png" alt="" class="bgImg" />
    <div class="login">
      <div class="login__back">
        <img src="@/assets/image/login-centerBg.png" alt="" />
      </div>
      <!-- 账号密码/ 验证码 登录 -->
      <div class="login--card" v-if="!setPwd">
        <div class="title">标签管理系统登录</div>
        <div class="flexStyle nav">
          <div
            :class="['nav--title', { activeTab: activeTab === 'passWord' }]"
            @click="changeActiveTab('passWord')"
          >
            <span>密码登录</span>
            <div class="line"></div>
          </div>
          <div
            :class="['nav--title', { activeTab: activeTab === 'smscode' }]"
            @click="changeActiveTab('smscode')"
          >
            <span>验证码登录</span>
            <div class="line"></div>
          </div>
        </div>
        <div>
          <a-form :form="form">
            <a-form-item>
              <a-input
                placeholder="请输入您的手机号/邮箱"
                v-decorator="[
                  'accountNumber',
                  {
                    rules: [
                      { required: true, message: '请输入绑定的手机号/邮箱' }
                    ]
                  }
                ]"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item v-if="activeTab === 'passWord'">
              <a-input-password
                placeholder="请输入您的密码"
                v-decorator="[
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }]
                  }
                ]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input-password>
            </a-form-item>
            <a-form-item v-if="activeTab === 'smscode'">
              <div style="display: flex; justify-content: space-between">
                <a-input
                  placeholder="验证码"
                  class="sort__input"
                  v-decorator="[
                    'numberVerificationCode',
                    {
                      rules: [{ required: true, message: '请输入验证码' }]
                    }
                  ]"
                />
                <div
                  class="sms"
                  @click="onSendSmsCaptcha"
                  :class="!dataConfig.reSendAllow ? 'sms__gray' : ''"
                >
                  {{ captchaSendMsg }}
                </div>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div @click="onSetPwd" class="clickTip">找回密码</div>
        <a-button class="btn--login" @click="onLogin">登录</a-button>
      </div>
      <!--找回密码-->
      <div class="login--card" v-if="setPwd">
        <a-page-header title="找回/设置密码" @back="onBack" />
        <a-form :form="form">
          <a-form-item>
            <a-input
              placeholder="请输入您的的手机号/邮箱"
              v-decorator="[
                'accountNumber',
                {
                  rules: [{ required: true, message: '请输入您的手机号/邮箱' }]
                }
              ]"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <div style="display: flex; justify-content: space-between">
              <a-input
                placeholder="请输入验证码"
                class="sort__input"
                v-decorator="[
                  'numberVerificationCode',
                  {
                    rules: [{ required: true, message: '请输入短信验证码' }]
                  }
                ]"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
              <div
                class="sms"
                @click="onSendSmsCaptchaPwd"
                :class="!dataConfig.reSendAllow ? 'sms__gray' : ''"
              >
                {{ captchaSendMsg }}
              </div>
            </div>
          </a-form-item>
          <a-form-item>
            <a-input-password
              placeholder="请输入新密码"
              v-decorator="[
                'password',
                {
                  rules: [{ required: true, message: '请输入新密码' }]
                }
              ]"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-input-password
              placeholder="确认新密码"
              v-decorator="[
                'newPassword',
                {
                  rules: [{ required: true, message: '请确认新密码' }]
                }
              ]"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <a-button class="btn--login" @click="onSubmitSetPwd">
            以此新密码登录
          </a-button>
        </a-form>
      </div>
    </div>
  </a-layout>
</template>

<script>
import { baseURL } from "@/services/HttpService";
import {
  loginByEmailVerifyCode,
  loginByPwd,
  loginByTrail,
  loginByVerifyCode,
  sendEmailCodeInLogin,
  sendEmailCodeInPwd,
  sendMessageCodeInLogin,
  sendMessageCodeInPwd,
  setPassword,
  setPasswordByEmail
} from "@/services/LoginService";

export default {
  name: "Login",
  data() {
    return {
      activeTab: "passWord",
      isScanCode: false,
      form: this.$form.createForm(this),
      captchaSendMsg: "获取验证码",
      setPwd: false, // 找回密码

      captchaImgUrl: baseURL + "/captchaImg",
      dataConfig: {
        counter: 60,
        reSendAllow: true
      },
      isSelect: true,
      disabled: true
    };
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.$router.push({
        name: "Login"
      });
    }
  },
  methods: {
    // 切换登录方式
    changeActiveTab(val) {
      if (this.activeTab === val) return;
      this.activeTab = val;
    },
    // 登录时  发送验证码
    onSendSmsCaptcha() {
      if (!this.dataConfig.reSendAllow) {
        return;
      }
      if (!this.form.getFieldsValue().accountNumber) {
        this.$message.info("请输入手机号/邮箱");
        return;
      }
      // 判断是邮箱验证码还是手机验证码
      const isMail = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(
        this.form.getFieldsValue().accountNumber
      );
      if (isMail) {
        // 给邮箱发验证码
        let params = `email=${this.form.getFieldsValue().accountNumber}`;
        sendEmailCodeInLogin(params).then(resp => {
          if (resp.data.code === "00000") {
            this.$message.success("已发送验证码");
            this.countDown();
          } else {
            this.$message.error(resp.data.message);
            this.captchaSendMsg = "重新获取";
            this.dataConfig.reSendAllow = true;
          }
        });
      } else {
        let params = `phone=${this.form.getFieldsValue().accountNumber}`;
        sendMessageCodeInLogin(params).then(resp => {
          if (resp.data.code === "00000") {
            this.$message.success("已发送验证码");
            this.countDown();
          } else {
            this.$message.error(resp.data.message);
            this.captchaSendMsg = "重新获取";
            this.dataConfig.reSendAllow = true;
          }
        });
      }
    },
    // 找回密码
    onSetPwd() {
      this.setPwd = true;
      this.form.resetFields();
      this.captchaSendMsg = "获取验证码";
      this.dataConfig = {
        counter: 60,
        reSendAllow: true
      };
    },
    onBack() {
      this.setPwd = false;
      this.form.resetFields();
    },
    // 找回密码的 发送验证码
    onSendSmsCaptchaPwd() {
      if (!this.dataConfig.reSendAllow) {
        return;
      }
      if (!this.form.getFieldsValue().accountNumber) {
        this.$message.info("请输入手机号/邮箱");
        return;
      }
      // 判断是邮箱验证码还是手机验证码
      const isMail = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(
        this.form.getFieldsValue().accountNumber
      );
      if (isMail) {
        // 给邮箱发验证码
        let params = `email=${this.form.getFieldsValue().accountNumber}`;
        sendEmailCodeInPwd(params).then(resp => {
          if (resp.data.code === "00000") {
            sessionStorage.setItem("isCreate", resp.data.data);
            this.$message.success("已发送验证码");
            this.countDown();
          } else {
            this.$message.error(resp.data.message);
            this.captchaSendMsg = "重新获取";
            this.dataConfig.reSendAllow = true;
          }
        });
      } else {
        let params = `phone=${this.form.getFieldsValue().accountNumber}`;
        sendMessageCodeInPwd(params).then(resp => {
          if (resp.data.code === "00000") {
            sessionStorage.setItem("isCreate", resp.data.data);
            this.$message.success("已发送验证码");
            this.countDown();
          } else {
            this.$message.error(resp.data.message);
            this.captchaSendMsg = "重新获取";
            this.dataConfig.reSendAllow = true;
          }
        });
      }
    },
    // 验证码 秒数
    countDown() {
      let num = this.dataConfig.counter;
      this.dataConfig.reSendAllow = false;
      this.captchaSendMsg = "重新获取(" + num + ")秒";
      for (let i = num; i >= 0; i--) {
        setTimeout(() => {
          this.captchaSendMsg = "重新获取(" + i + ")秒";
          if (i == 0) {
            this.captchaSendMsg = "重新获取";
            this.dataConfig.reSendAllow = true;
          }
        }, (num - i + 1) * 1000);
      }
    },
    // 以此新密码登录
    onSubmitSetPwd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.password !== values.newPassword) {
            this.$message.info("两次输入的密码不一致");
            return;
          }
          // 判断是邮箱验证码还是手机验证码
          const isMail = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(
            values.accountNumber
          );
          if (isMail) {
            let params = `email=${values.accountNumber}&captcha=${values.numberVerificationCode}&password=${values.password}`;
            setPasswordByEmail(params).then(resp => {
              if (resp.data.code === "00000") {
                const data = resp.data.data;
                this.hasRealLogin(data);
              } else {
                this.$message.error(resp.data.message);
              }
            });
          } else {
            let params = `phone=${values.accountNumber}&captcha=${values.numberVerificationCode}&password=${values.password}`;
            setPassword(params).then(resp => {
              if (resp.data.code === "00000") {
                const data = resp.data.data;
                this.hasRealLogin(data);
              } else {
                this.$message.error(resp.data.message);
              }
            });
          }
        }
      });
    },
    // 拿到cookie之后 登录
    hasRealLogin(data) {
      const params = {
        nickname: data.userName,
        outId: data.userId,
        wechatId: data.wechat ? data.wechat : "",
        avatar: data.avatar,
        email: data.email ? data.email : "",
        mobile: data.phone ? data.phone : "",
        sex: data.sex ? data.sex : ""
      };
      if (params) {
        loginByTrail(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            localStorage.setItem("userId", data.userId);
            localStorage.setItem("nickName", data.nickname);
            localStorage.setItem("profilePictureUrl", data.profilePictureUrl);
            localStorage.setItem("roleIds", data.roleIds);
            this.$message.success("登录成功");
            localStorage.setItem("token", data.token);
            this.$router.push({ name: "AdminManage" });
            this.$store.state.isMyPage = false;
          } else {
            this.$message.error(resp.data.errorMsg || "请重新登录");
          }
        });
      }
    },
    // 登录
    onLogin() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.activeTab === "passWord") {
            this.loginByAccount(values);
          } else {
            this.loginBySms(values);
          }
        }
      });
    },
    // 账号密码登录
    loginByAccount(values) {
      // 外部接口
      let params = `account=${values.accountNumber}&password=${values.password}`;
      loginByPwd(params).then(resp => {
        if (resp.data.code === "00000") {
          const data = resp.data.data;
          this.hasRealLogin(data);
        } else {
          this.$message.error(resp.data.message);
        }
      });
    },
    // 验证码登录
    loginBySms(values) {
      // 判断是邮箱验证码还是手机验证码
      const isMail = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(
        values.accountNumber
      );
      if (isMail) {
        let params = `email=${values.accountNumber}&captcha=${values.numberVerificationCode}`;
        loginByEmailVerifyCode(params).then(resp => {
          if (resp.data.code === "00000") {
            const data = resp.data.data;
            this.hasRealLogin(data);
          } else {
            this.$message.error(resp.data.message);
          }
        });
      } else {
        let params = `phone=${values.accountNumber}&captcha=${values.numberVerificationCode}`;
        loginByVerifyCode(params).then(resp => {
          if (resp.data.code === "00000") {
            const data = resp.data.data;
            this.hasRealLogin(data);
          } else {
            this.$message.error(resp.data.message);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.flexStyle {
  display: flex;
  align-items: center;
}
.main__container {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  min-width: 1200px;
}
.bgImg {
  width: 100%;
  height: 100vh;
}
.login__back img {
  height: 466px;
  object-fit: contain;
}
.login {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 25 / 14;
  background: white;
  height: 466px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
}
.login--card {
  box-sizing: border-box;
  padding: 42px;
}
.title {
  font-size: 26px;
  text-align: center;
  color: #3ea7f8;
  margin-bottom: 20px;
}
.nav {
  justify-content: center;
  color: #b3b3b3;
}
.activeTab {
  font-size: 16px;
  color: #86c9fa;
}
.nav--title {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.activeTab .line {
  height: 2px;
  background: #86c9fa;
}
.clickTip {
  cursor: pointer;
  text-align: center;
  color: #86c9fa;
  margin-bottom: 20px;
  margin-top: 20px;
}
.btn--login {
  width: 100%;
  background-color: #3185ee;
  color: white;
  border: none;
  height: 42px;
}

.logo__container img {
  height: 100%;
  object-fit: contain;
  margin-left: 16%;
}
@media (max-width: 1070px) {
  .logo__container img {
    margin-left: 40px;
  }
}
.login__container {
  flex: 1;
  display: flex;
  justify-content: center;
}
.login__back img {
  height: 466px;
  object-fit: contain;
}
.login__fill {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 42px 10px 10px 48px;
}
.login__way {
  width: 270px;
  display: flex;
  justify-content: space-between;
}
.login__way--item {
  color: #666666;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.scan__code {
  box-sizing: border-box;
  padding: 50px 0 0 0;
}
.scan__item {
  width: 72px;
  height: 30px;
  border-radius: 280px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.28);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}
.active {
  color: #0052d9;
  font-size: 20px;
}
.ant-form {
  width: 270px;
  margin-top: 24px;
}
.operation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 28px;
}
a {
  color: #0052d9;
}

.sms {
  width: 95px;
  color: #0052d9;
  cursor: pointer;
}
.ant-page-header {
  padding: 0;
  width: 270px;
}
/deep/ .ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
/deep/ .anticon svg {
  color: #999999;
}
/deep/ .ant-page-header-heading-title {
  color: #0052d9;
  padding-right: 0px;
}

/deep/ .ant-input {
  height: 34px;
}
.sort__input {
  width: 165px;
}
.ant-checkbox-wrapper {
  width: 24px;
  margin-right: 5px;
}
/deep/ .ant-checkbox + span {
  color: #666666;
  font-weight: 300;
}
.ant-form-item {
  margin-bottom: 14px;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 3px !important;
}
.sms__gray {
  color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  cursor: none;
}
/deep/.ant-form-item {
  display: block !important;
}
</style>
