var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_vm._v("系统标签管理")]),_c('div',{staticClass:"content-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.onSearch}},[_c('a-row',[_c('a-col',{attrs:{"span":7}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keyword']),expression:"['keyword']"}],attrs:{"allow-clear":"","placeholder":"请输入关键字"},on:{"change":_vm.onChangeValue}})],1)],1),_c('a-col',{staticClass:"search__btn",attrs:{"span":7}},[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btn-reset",attrs:{"type":"white","icon":"undo"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),(_vm.roleIds.includes('SYS_ADMIN'))?_c('a-row',{staticClass:"add"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onAdd}},[_vm._v(" 添加标签 ")])],1):_vm._e(),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tableData,"columns":_vm.columns,"pagination":_vm.pagination,"row-key":(record, index) => {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('a-space',{},[_c('a',{on:{"click":function($event){return _vm.toSecondTag(record)}}},[_vm._v("查看下级标签")]),(_vm.roleIds.includes('SYS_ADMIN'))?_c('a',{on:{"click":function($event){return _vm.openEdit(record)}}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.roleIds.includes('SYS_ADMIN'))?_c('a',{on:{"click":function($event){return _vm.openDelete(record)}}},[_vm._v(" 删除 ")]):_vm._e()])}}])})],1)],1),_c('a-modal',{attrs:{"title":_vm.modalTitle,"visible":_vm.visible,"okText":"保存","cancelText":"取消"},on:{"ok":_vm.addAdmin,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.addForm},on:{"submit":_vm.addAdmin}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"一级标签名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tagName',
            {
              rules: [{ required: true, message: '请输入一级标签名称' }]
            }
          ]),expression:"[\n            'tagName',\n            {\n              rules: [{ required: true, message: '请输入一级标签名称' }]\n            }\n          ]"}]})],1)],1)],1),_c('a-modal',{attrs:{"title":"删除一级标签","visible":_vm.delVisible,"okText":"删除","cancelText":"取消"},on:{"ok":_vm.deleteTag,"cancel":function($event){_vm.delVisible = false}}},[_c('div',{staticClass:"tips"},[_vm._v("确定删除名为【"+_vm._s(_vm.tagInfo.tagName)+"】的一级标签吗?")]),_c('div',{staticClass:"tips"},[_vm._v("该标签的下级标签也会一并删除")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }