import { apiClient } from "@/services/HttpService";

// 系统标签管理 相关接口

// 查询系统标签列表
export function getSystemTagList(params) {
  return apiClient.get(
    `/sys-tag?pageNum=${params.pageNum}&pageSize=${params.pageSize}&keyword=${params.keyword}&parentTagId=${params.parentTagId}`
  );
}

// 创建系统标签
export function addSystemTag(params) {
  return apiClient.post("/sys-tag", params);
}

// 删除标签
export function deleteSystemTag(tagId) {
  return apiClient.delete(`/sys-tag/${tagId}`);
}

// 编辑标签
export function editSystemTag(tagId, params) {
  return apiClient.put(`/sys-tag/${tagId}?tagName=${params.tagName}`);
}
