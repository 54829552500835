<!--系统标签管理-->
<template>
  <a-layout-content>
    <div class="nav">系统标签管理</div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="">
              <a-input
                v-decorator="['keyword']"
                allow-clear
                placeholder="请输入关键字"
                @change="onChangeValue"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-row class="add" v-if="roleIds.includes('SYS_ADMIN')">
        <a-button type="primary" @click="onAdd">
          添加标签
        </a-button>
      </a-row>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="toSecondTag(record)">查看下级标签</a>
            <a @click="openEdit(record)" v-if="roleIds.includes('SYS_ADMIN')">
              编辑
            </a>
            <a @click="openDelete(record)" v-if="roleIds.includes('SYS_ADMIN')">
              删除
            </a>
          </a-space>
        </a-table>
      </div>
    </div>

    <!--新增/编辑弹窗-->
    <a-modal
      :title="modalTitle"
      :visible="visible"
      okText="保存"
      cancelText="取消"
      @ok="addAdmin"
      @cancel="handleCancel"
    >
      <a-form :form="addForm" @submit="addAdmin">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="一级标签名称"
        >
          <a-input
            v-decorator="[
              'tagName',
              {
                rules: [{ required: true, message: '请输入一级标签名称' }]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!--删除弹窗-->
    <a-modal
      title="删除一级标签"
      :visible="delVisible"
      okText="删除"
      cancelText="取消"
      @ok="deleteTag"
      @cancel="delVisible = false"
    >
      <div class="tips">确定删除名为【{{ tagInfo.tagName }}】的一级标签吗?</div>
      <div class="tips">该标签的下级标签也会一并删除</div>
    </a-modal>
  </a-layout-content>
</template>
<script>
import {
  getSystemTagList,
  addSystemTag,
  deleteSystemTag,
  editSystemTag
} from "@/services/systemTag";
const columns = [
  {
    title: "标签名称",
    dataIndex: "tagName"
  },
  {
    title: "创建人",
    dataIndex: "createdBy"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};
export default {
  name: "SystemTag",
  data() {
    return {
      roleIds: localStorage.getItem("roleIds"),
      columns,
      formItemLayout,
      modalTitle: "", // 弹窗标题
      form: this.$form.createForm(this),
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: [],
      addForm: this.$form.createForm(this),
      visible: false,
      tagInfo: {},
      delVisible: false // 删除弹窗
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    onChangeValue(row) {
      if (row.type === "click") {
        this.onReset();
      }
    },
    // 获取表格数据
    async getTableList() {
      const params = this.form.getFieldsValue();
      const obj = {
        keyword: params.keyword ? params.keyword : "",
        parentTagId: "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getSystemTagList(obj);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.pagination.current = 1;
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    // 打开删除弹窗
    openDelete(record) {
      this.tagInfo = record;
      this.delVisible = true;
    },
    // 删除标签
    async deleteTag() {
      const { data } = await deleteSystemTag(this.tagInfo.tagId);
      if (data.code === "SUCCESS") {
        this.$message.success("删除成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
      this.delVisible = false;
      this.tagInfo = {};
    },
    // 操作-点击编辑按钮
    openEdit(record) {
      this.modalTitle = "编辑新的一级标签";
      this.tagInfo = record;
      this.visible = true;
      this.$nextTick(() => {
        this.addForm.setFieldsValue({
          tagName: record.tagName
        });
      });
    },
    // 点击添加按钮
    onAdd() {
      this.modalTitle = "添加新的一级标签";
      this.addForm.resetFields();
      this.visible = true;
    },
    // 提交 - 添加/编辑一级标签
    async addAdmin() {
      const params = this.addForm.getFieldsValue();
      if (this.tagInfo.tagId) {
        //   编辑
        const { data } = await editSystemTag(this.tagInfo.tagId, params);
        if (data.code === "SUCCESS") {
          this.$message.success("编辑成功");
          this.getTableList();
        } else {
          this.$message.error(data.errorMsg);
        }
      } else {
        // 新增
        const { data } = await addSystemTag(params);
        if (data.code === "SUCCESS") {
          this.$message.success("添加成功");
          this.getTableList();
        } else {
          this.$message.error(data.errorMsg);
        }
      }
      this.visible = false;
      this.tagInfo = {};
    },
    handleCancel() {
      this.addForm.resetFields();
      this.visible = false;
    },
    // 跳转下级标签
    toSecondTag(record) {
      this.$router.push({
        name: "SecondTag",
        query: {
          id: record.tagId,
          tagName: record.tagName
        }
      });
    }
  }
};
</script>
<style scoped>
.nav {
  background-color: white;
  font-size: 30px;
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #cccccc;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  padding: 30px;
}
.add {
  margin-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  margin-top: 4px;
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
.tips {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}
</style>
