<!--数据统计-->
<template>
  <a-layout-content>
    <div class="nav">数据统计</div>
    <div class="content-container">
      <!--信息部分-->
      <div class="flexStyle">
        <div class="card">
          <div class="title">系统标签与自定义标签的数据统计</div>
          <div class="content">
            <div class="item">系统标签数量: {{ info.sysTagCount }}</div>
            <div class="item">自定义标签数量: {{ info.customTagCount }}</div>
            <div class="item">总计: {{ info.totalCount }}</div>
            <div class="item">
              系统标签: {{ (info.proportionOfSystemTags * 100).toFixed(2) }}%
            </div>
            <div class="item">
              自定义标签占比:
              {{ (info.proportionOfCustomTags * 100).toFixed(2) }}%
            </div>
          </div>
        </div>
        <div class="card">
          <div class="title">标签组数据统计</div>
          <div class="content">
            <div class="item">标签组数量: {{ info.tagGroupCount }}</div>
          </div>
        </div>
      </div>
      <!--饼图部分-->
      <div class="echarts">
        <div id="main" style="width: 500px; height: 500px"></div>
      </div>
    </div>
  </a-layout-content>
</template>
<script>
import { getTotalTagData } from "@/services/tagGroup";
import * as echarts from "echarts";
export default {
  name: "DataStatistics",
  data() {
    return {
      info: {},
      pieData: [
        { value: 0, name: "系统标签" },
        { value: 0, name: "自定义标签" }
      ]
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取表格数据
    async getData() {
      const { data } = await getTotalTagData();
      if (data.code === "SUCCESS") {
        this.info = data.data;
        this.pieData[0].value = data.data.sysTagCount;
        this.pieData[1].value = data.data.customTagCount;
        this.initEcharts();
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    initEcharts() {
      const chartDom = document.getElementById("main");
      const myChart = echarts.init(chartDom);
      const option = {
        title: {
          text: "",
          subtext: "",
          left: "center"
        },
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "horizontal",
          bottom: "bottom"
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: this.pieData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
};
</script>
<style scoped>
.nav {
  background-color: white;
  font-size: 30px;
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #cccccc;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  padding: 30px;
}
.flexStyle {
  display: flex;
}
.card {
  width: 400px;
  height: 250px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-left: 60px;
}
.title {
  font-size: 20px;
  color: #333;
  text-align: center;
}
.content {
  padding: 20px;
  padding-left: 40px;
  color: #333;
}
.item {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.echarts {
  padding-left: 40px;
}
</style>
