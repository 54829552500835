import { apiClient } from "@/services/HttpService";

// 查询标签组
export function getTagGroupList(params) {
  return apiClient.get(
    `/tag-group?pageNum=${params.pageNum}&pageSize=${params.pageSize}&keyword=${params.keyword}`
  );
}

// 新增标签组
export function addTagGroup(params) {
  return apiClient.post("/tag-group", params);
}

// 编辑标签组
export function editTagGroup(tagGroupId, params) {
  return apiClient.put(
    `/tag-group/${tagGroupId}?tagGroupName=${params.tagGroupName}`
  );
}

// 删除标签组
export function deleteTagGroup(tagGroupId) {
  return apiClient.delete(`/tag-group/${tagGroupId}`);
}

// 将标签从标签组移除
export function delTagFromGroup(tagGroupId, tagId) {
  return apiClient.delete(`/tag-group/${tagGroupId}/tag/${tagId}`);
}

// 标签组添加标签
export function addTagToGroup(tagGroupId, params) {
  return apiClient.post(
    `/tag-group/${tagGroupId}/tag?tagName=${params.tagName}`
  );
}
// 调整标签组内标签顺序
export function editTagGroupOrder(tagGroupId, params) {
  return apiClient.put(`/tag-group/${tagGroupId}/tag`, params);
}

// 标签数据统计
export function getTotalTagData() {
  return apiClient.get(`/statistics/tags`);
}
