<!--管理员管理-->
<template>
  <a-layout-content>
    <div class="nav">
      管理员管理
    </div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="">
              <a-input
                v-decorator="['keyword']"
                allow-clear
                placeholder="请输入姓名/账号"
                @change="onChangeValue"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-row class="add">
        <a-button type="primary" @click="onAdd">
          添加新的管理员
        </a-button>
      </a-row>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="roleIdList" slot-scope="text, record">
            <div>{{ transValue(record.roleIdList) }}</div>
          </template>
          <template slot="createTime" slot-scope="text, record">
            <div>{{ tsFormat(record.createTime) }}</div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a-popconfirm
              :title="`您确定要移除该管理员吗?`"
              ok-text="确认"
              cancel-text="取消"
              @confirm="deleteRole(record)"
            >
              <a>删除</a>
            </a-popconfirm>
            <a @click="editRole(record)">管理角色</a>
          </a-space>
        </a-table>
      </div>
    </div>

    <!--新增弹窗-->
    <a-modal
      title="添加新的管理员"
      :visible="visible"
      okText="保存"
      cancelText="取消"
      @ok="addAdmin"
      @cancel="handleCancel"
    >
      <a-form :form="addForm" @submit="addAdmin">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="管理员手机号/邮箱"
        >
          <a-input
            v-decorator="[
              'userName',
              {
                rules: [
                  { required: true, message: '请输入管理员手机号/邮箱' },
                  {
                    validator: validateEmail,
                    message: '请输入正确格式的手机号/邮箱'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="管理员角色"
        >
          <a-checkbox-group
            v-decorator="[
              'roleIds',
              {
                rules: [{ required: true, message: '请选择管理角色' }]
              }
            ]"
          >
            <a-checkbox value="SYS_ADMIN">标签系统管理员</a-checkbox>
            <a-checkbox value="APP_ADMIN">应用系统管理员</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
    <!--修改弹窗-->
    <a-modal
      title="管理该管理员角色"
      :visible="editVisible"
      okText="保存"
      cancelText="取消"
      @ok="editAdmin"
      @cancel="editAdminCancel"
    >
      <a-form :form="editForm" @submit="addAdmin">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="管理员手机号"
        >
          <div>{{ adminInfo.mobile || "--" }}</div>
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="管理员邮箱"
        >
          <div>{{ adminInfo.email || "--" }}</div>
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="管理员姓名"
        >
          <div>{{ adminInfo.nickName || "--" }}</div>
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="管理员角色"
        >
          <a-checkbox-group
            v-decorator="[
              'roleIds',
              {
                rules: [{ required: true, message: '请选择管理角色' }]
              }
            ]"
          >
            <a-checkbox value="SYS_ADMIN">标签系统管理员</a-checkbox>
            <a-checkbox value="APP_ADMIN">应用系统管理员</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>
<script>
import { tsFormat } from "@/components/DateUtils";
import {
  addAdmin,
  getAdminList,
  deleteAdmin,
  getAdminDetail,
  editAdmin
} from "@/services/admin";
const columns = [
  {
    title: "姓名",
    dataIndex: "nickName"
  },
  {
    title: "手机号",
    dataIndex: "mobile"
  },
  {
    title: "邮箱",
    dataIndex: "email"
  },
  {
    title: "角色",
    dataIndex: "roleIdList",
    scopedSlots: { customRender: "roleIdList" }
  },
  {
    title: "添加成时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};
export default {
  name: "AdminManage",
  data() {
    return {
      columns,
      formItemLayout,
      form: this.$form.createForm(this),
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: [],
      addForm: this.$form.createForm(this),
      visible: false,
      adminInfo: {},
      editForm: this.$form.createForm(this),
      editVisible: false // 修改弹窗
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    tsFormat,
    onChangeValue(row) {
      if (row.type === "click") {
        this.onReset();
      }
    },
    // 获取表格数据
    async getTableList() {
      const params = this.form.getFieldsValue();
      const obj = {
        keyword: params.keyword ? params.keyword : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getAdminList(obj);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.pagination.current = 1;
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    // 删除管理员
    async deleteRole(record) {
      const { data } = await deleteAdmin(record.userId);
      if (data.code === "SUCCESS") {
        this.$message.success("删除成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 操作-点击管理角色按钮
    async editRole(record) {
      const { data } = await getAdminDetail(record.userId);
      if (data.code === "SUCCESS") {
        this.adminInfo = data.data;
        this.editVisible = true;
        this.$nextTick(() => {
          this.editForm.setFieldsValue({
            roleIds: data.data.roleIdList
          });
        });
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 点击添加按钮
    onAdd() {
      this.addForm.resetFields();
      this.visible = true;
    },
    // 提交 - 添加管理员
    addAdmin() {
      this.addForm.validateFields(async err => {
        if (!err) {
          const params = this.addForm.getFieldsValue();
          const { data } = await addAdmin(params);
          if (data.code === "SUCCESS") {
            this.$message.success("添加成功");
            this.getTableList();
          } else {
            this.$message.error(data.errorMsg);
          }
          this.visible = false;
        }
      });
    },
    handleCancel() {
      this.addForm.resetFields();
      this.visible = false;
    },
    // 提交-管理管理员
    async editAdmin() {
      const { data } = await editAdmin(
        this.adminInfo.userId,
        this.editForm.getFieldsValue().roleIds
      );
      if (data.code === "SUCCESS") {
        this.$message.success("修改成功");
        this.getTableList();
      } else {
        this.$message.error(data.errorMsg);
      }
      this.editVisible = false;
    },
    // 管理弹窗取消
    editAdminCancel() {
      this.editForm.resetFields();
      this.editVisible = false;
    },
    // 校验 邮箱和手机号
    validateEmail(rule, value) {
      // 不符合手机号和邮箱格式
      if (
        !/^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(value) &&
        !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)
      ) {
        return false;
      } else {
        return true;
      }
    },
    transValue(val) {
      const arr = [];
      val.forEach(item => {
        if (item === "SYS_ADMIN") {
          arr.push("标签系统管理员");
        } else if (item === "APP_ADMIN") {
          arr.push("应用系统管理员");
        }
      });
      return arr.join("、");
    }
  }
};
</script>
<style scoped>
.nav {
  background-color: white;
  font-size: 30px;
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #cccccc;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  padding: 30px;
}
.add {
  margin-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  margin-top: 4px;
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>
