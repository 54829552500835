import { apiClient } from "@/services/HttpService";

// 自定义标签 相关接口

// 查询用户自定义标签列表
export function getCustomTagList(params) {
  return apiClient.get(
    `/custom-tag?pageNum=${params.pageNum}&pageSize=${params.pageSize}&keyword=${params.keyword}&parentTagId=${params.parentTagId}`
  );
}

// 删除标签
export function deleteCustomTag(tagId) {
  return apiClient.delete(`/custom-tag/${tagId}`);
}