import { apiClient } from "@/services/HttpService";

// 管理员管理相关接口

// 查询管理员列表
export function getAdminList(params) {
  return apiClient.get(
    `/admin?pageNum=${params.pageNum}&pageSize=${params.pageSize}&keyword=${params.keyword}`
  );
}

// 新增管理员
export function addAdmin(params) {
  return apiClient.post("/admin", params);
}

// 删除管理员
export function deleteAdmin(userId) {
  return apiClient.delete(`/admin/${userId}`);
}

// 获取管理员详情
export function getAdminDetail(userId) {
  return apiClient.get(`/admin/${userId}`);
}

// 编辑管理员

export function editAdmin(userId, params) {
  return apiClient.put(`/admin/${userId}`, params);
}
